<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>지식관리</h1>
            <div class="is-right">
            </div>
        </div>

        <!--조회-->
        <div class="jh-search-form">
            <table>
                <colgroup>
                    <col width="55px">
                    <col width="250px">
                    <col width="70px">
                    <col width="200px">
                    <col width="80px">
                    <col width="150px">
                    <col width="90px">
                    <col width="350px">
                    <col>
                </colgroup>
                <tr>
                    <th><label>등록일자</label></th>
                    <td>
                        <div class="jh-cols">                        
                            <v-menu v-model="menuStartDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="from" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuStartDate = false"
                                    maxlength="10" @input="fromOnInput" @click:clear="dates[0] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[0]" @input="menuStartDate = false" no-title locale="ko-KR" :max="dates[1]" :day-format="mixin_getDate" @change="startDate"></v-date-picker>
                            </v-menu>
                            <span class="jh-unit">~</span>                       
                            <v-menu v-model="menuEndDate" :close-on-content-click="false" transition="scale-transition" min-width="auto" offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="to" v-bind="attrs" v-on="on" class="jh-form" clearable append-icon="jh-icon-calendar is-blue" @keyup.enter="menuEndDate = false"
                                    maxlength="10" @input="toOnInput" @click:clear="dates[1] = ''" oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');"></v-text-field>
                                </template>
                                <v-date-picker class="jh-calendar" v-model="dates[1]" @input="menuEndDate = false" no-title locale="ko-KR" :min="dates[0]" :day-format="mixin_getDate" @change="endDate"></v-date-picker>
                            </v-menu>
                        </div>
                    </td>
                    <th><label>상담사</label></th>
                    <td>
                        <div class="jh-cols">
                            <input type="text" class="jh-form is-col-fix" style="width: 70px;" v-model="userInfo.USER_ID">
                            <input type="text" class="jh-form" v-model="userInfo.USER_NM">
                            <v-btn class="jh-btn is-fill has-icon-only" @click="showDialog('P101801')"><i class="jh-icon-search-sm is-white"></i></v-btn>
                        </div>
                    </td>
                    <th><label>지식분류</label></th>
                    <td>
                        <v-select class="jh-form" :items="mainCategoryList" v-model="searchValue.MAIN_CTGR_CD"></v-select>
                    </td>
                    <th><label>지식정보명</label></th>
                    <td><input type="text" class="jh-form" v-model="searchValue.TITLE" @keypress.enter="search"></td>
                    <td class="has-search"><v-btn class="jh-btn is-search" @click="search">조회</v-btn></td>
                </tr>
            </table>
        </div>
        <!--//조회-->

        <div class="jh-cols">
            <!--리스트-->
            <div class="jh-form-wrap is-pt-10">
                <div class="jh-ui-header">
                    <h2>지식정보</h2>
                    <div class="is-right">
                    </div>
                </div>

                <data-tables
                    ref="dataTable"
                    :data-table-options="dataTableOptions"
                    @click:row="onClickRow"
                    :paginationOptions="paginationOptions"
                    v-model="selectedRows"
                >
                </data-tables>
            </div>
            <!--//리스트-->

            <!--상세-->
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>지식 상세정보</h1>
                    <div class="is-right">
                    </div>
                </div>
                <div class="jh-card-body">
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="110px">
                            <col>
                            <col width="110px">
                            <col>
                            <col width="110px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label>지식정보ID</label></th>
                                <td>
                                    <span class="is-value">{{ !knowledgeDetail.ID ? "" : knowledgeDetail.ID }}</span>
                                </td>
                                <th><label>분류</label></th>
                                <td>
                                    <v-select class="jh-form" :items="filteredMainCategoryList()" placeholder="선택" v-model="knowledgeDetail.MAIN_CTGR_CD" @change="changeMainCategoryCode"></v-select>
                                </td>
                                <th><label>등록일시</label></th>
                                <td>
                                    <span class="is-value">{{ knowledgeDetail.REG_DTTM }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th><label>등록자</label></th>
                                <td>
                                    <span class="is-value">{{ knowledgeDetail.REG_NM }}</span>
                                </td>
                                <th><label>수정자</label></th>
                                <td>
                                    <span class="is-value">{{ knowledgeDetail.UPD_NM }}</span>
                                </td>
                                <th><label>수정일시</label></th>
                                <td>
                                    <span class="is-value">{{ knowledgeDetail.UPD_DTTM }}</span>
                                </td>
                            </tr>
                            <tr>
                                <th><label>지식정보명</label></th>
                                <td colspan="5">
                                    <input type="text" class="jh-form" v-model="knowledgeDetail.TITLE">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="jh-editor is-mt-5"><ckeditor v-if="lodash.includes($options.name, active_tab_id)" v-model="knowledgeDetail.CONTENT" :config="editorConfig"></ckeditor></div>
                </div>
                <div class="jh-card-footer">                
                    <v-btn class="jh-btn is-md" @click="reset">초기화</v-btn>
                    <v-btn class="jh-btn is-md is-main" v-if="mixin_set_btn($options.name, 'btnSave')" :loading="isLoading" @click="save">저장</v-btn>
                    <v-btn class="jh-btn is-md is-del" v-if="mixin_set_btn($options.name, 'btnDelete')" :disabled="!knowledgeDetail.ID" :loading="isLoading" @click="this.delete">삭제</v-btn>
                </div>
            </div>
            <!--상세-->
        </div>

        
        <v-dialog max-width="1000" v-model="dialogP101801">
            <dialog-P101801
                @hide="hideDialog('P101801')"
                :userInfo="userInfo"
                :param="{ page: 'E040102' }"
                @input="userInfo = $event"
                @selectUser="[hideDialog('P101801')]"
            ></dialog-P101801>
            <!-- <template v-slot:body></template> -->
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin } from "@/mixin/mixin.js";
import dataTables from "@/components/DataTables";

import iconv from "iconv-lite";

import dialogP101801 from "./P101801.vue";

export default {
    name: "MENU_E040102", //name은 'MENU_' + 파일명 조합
    mixins: [mixin],
    components: {
        dialogP101801,
        dataTables
    },
    data() {
        return {
            lodash: _,

            isLoading: false,

            dialogP101801: false,
            
            menuStartDate: false,
            menuEndDate: false,
            /*
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD"),
            dates: [
                moment().format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD")
            ],*/
            from: '',
            to: '',
            dates: [
                '',
                ''
            ],

            mainCategoryList: [],
            subCategoryList: [
                { text: "전체", value: null }
            ],
            filteredSubCategoryList: [],

            searchValue: {
                USER_ID: null,
                USER_NM: null,
                MAIN_CTGR_CD: null,
                SUB_CTGR_CD: null,
                TITLE: null
            },
            userInfo: {
                USER_ID: '',
                USER_NM: '',
                //USER_ID: this.$store.getters["userStore/GE_USER_ROLE"].userId,
                //USER_NM: this.$store.getters["userStore/GE_USER_ROLE"].userName,
                // USER_NICK: this.$store.getters["userStore/GE_USER_ROLE"].userNick,
                USER_ATTR_A: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_A,
                USER_ATTR_B: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_B,
                USER_ATTR_C: this.$store.getters["userStore/GE_USER_ROLE"].USER_ATTR_C,
            },

            dataTableOptions: {
                fixedHeader: true,
                headers: [
                    { text: "NO", value: "ROWNUM", align: "center", width: "50px" },
                    { text: "지식정보ID", value: "ID", align: "center", width: "90px" },
                    { text: "분류", value: "MAIN_CTGR_NM", align: "center", width: "120px" },                    
                    { text: "지식정보명", value: "TITLE" },
                    { text: "수정일시", value: "UPD_DTTM", align: "center", width: "160px" },
                ],
                height: "600px",
                hideDefaultFooter: true,
                itemKey: "ID",
                itemsPerPage: 50,
                noDataText: "조회된 결과가 없습니다.",
                page: 1,
                singleSelect: true,

                load: this.getList
            },
            paginationOptions: {
                totalVisible: 10
            },

            editorConfig: {
                extraPlugins: "font, colorbutton, colordialog, openlink, spacingsliders",
                height: "370px",
                toolbar: [
                    { name: "clipboard", items: [ "Cut", "Copy", "Paste", "-", "Undo", "Redo" ] },//, "PasteText", "PasteFromWord"
                    { name: "editing" }, //, items: [ "Scayt" ]
                    { name: "links", items: [ "Link", "Unlink" ] },//, "Anchor"
                    { name: "insert", items: [ "Image", "Table", "HorizontalRule", "SpecialChar" ] },
                    { name: "forms" },
                    { name: "styles", items: [ "Font", "FontSize" ] },
                    { name: "styles", items: [ "Styles", "Format" ] },
                    { name: "others", items: [ "-" ] },
                    "/",
                    { name: "colors", items: [ "TextColor", "BGColor", "spacingsliders" ] },
                    { name: "basicstyles", items: [ "Bold", "Italic", "Strike", "-", "RemoveFormat" ] },
                    { name: "paragraph", items: [ "NumberedList", "BulletedList", "-", "Outdent", "Indent", "-", "Blockquote" ] },
                    { name: "document", items: [ "Source" ] },
                    { name: "tools", items: [ "Maximize" ] },
                    { name: "about", items: [] }
                ],
                toolbarCanCollapse:  true
            },

            requestData: {
                headers: {
                    SERVICE: "knowledge",
                    TYPE: "BIZ_SERVICE",
                },
                sendData: {},
            },

            selectedKey: null,
            selectedRows: [],

            knowledgeDetail: {
                ID: null,
                MAIN_CTGR_CD: null,
                MAIN_CTGR_NM: null,
                SUB_CTGR_CD: null,
                SUB_CTGR_NM: null,
                TITLE: null,
                CONTENT: null,
                REG_ID: null,
                REG_NM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            }
        }
    },
    computed: {
        ...mapGetters({
            active_tab_id: "commonStore/GE_COMMON_ACTIVE_TAB_ID",
        }),
        dataTableInstance: function() {
            return this.$refs.dataTable;
        },
        contentLen: function() {
            // 실제 DB에 저장되는 escape 문자로 변환된 문자열의 사이즈를 체크
            return iconv.encode(this.restoreXSS_CKeditorDec(this.knowledgeDetail.CONTENT), "utf-8").length;
        }
    },
    methods: {
        init: async function() {
            // 헤더 초기화 및 세팅
            this.setDataTableParams();

            const resCategoryList = await this.getCategoryList();

            if (
                !this.mixin_isEmpty(resCategoryList) &&
                !resCategoryList.HEADER.ERROR_FLAG
            ) {
                this.mainCategoryList = [
                    { text: "전체", value: null },
                    ...resCategoryList.DATA
                ];
            }
        },
        getCategoryList: function(level, parentCode) {
            if (!level) level = "MAIN";
            
            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "list",
                    SERVICE: "knowledge",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/knowledge/category/list"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData["LEVEL"] = level;
            requestData.sendData["PRNT_CD"] = parentCode;

            return this.common_postCall(requestData);
        },
        setDataTableParams: function() {
            // header 세팅
            this.requestData.headers.URL = "/api/knowledge/list";
            this.requestData.headers.METHOD = "list";
            this.requestData.headers.ASYNC = false;

            // sendData 세팅
            this.requestData.sendData.FROM = this.dates[0];
            this.requestData.sendData.TO = this.dates[1];
            this.requestData.sendData.USER_ID = this.userInfo.USER_ID;
            this.requestData.sendData.MAIN_CTGR_CD = this.searchValue.MAIN_CTGR_CD;
            this.requestData.sendData.SUB_CTGR_CD = this.searchValue.SUB_CTGR_CD;
            this.requestData.sendData.TITLE = this.searchValue.TITLE;
            this.requestData.sendData.CLASS = "02";
        },
        getList: async function(loadOptions) {
            // 페이징 정보 세팅
            this.requestData.headers.ROW_CNT = loadOptions.itemsPerPage;
            this.requestData.headers.PAGES_CNT = loadOptions.page;
            if (loadOptions.sortBy && loadOptions.sortBy.length > 0)
                this.requestData.sendData.SORT_ORDR = loadOptions.sortBy[0];
            else this.requestData.sendData.SORT_ORDR = undefined;
            if (loadOptions.sortDesc && loadOptions.sortDesc.length > 0)
                this.requestData.sendData.SORT_DRCT = loadOptions.sortDesc[0] ? "desc" : "asc";
            else this.requestData.sendData.SORT_DRCT = undefined;

            return this.common_postCall(this.requestData).then((response) => {
                if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }

                let header = response.HEADER;
                let data = response.DATA;
                _.each(data, (item) => {
                    item.TITLE = this.restoreXSS(item.TITLE);
                    item.CONTENT = this.restoreXSS_CKeditor(item.CONTENT);
                    // 이행 데이터 중 tag의 attribute의 쌍따옴표가 escape 문자로 변경된 경우가 존재하여 변환해줘야 정상 처리됨
                    item.CONTENT = _.replace(item.CONTENT, /&quot;/gi, '"');
                    item.UPD_DTTM = item.UPD_DTTM.substr(0, 19);
                    item.REG_DTTM = item.REG_DTTM.substr(0, 19);
                });

                return {
                    data: data,
                    totalCount: header.TOT_COUNT,
                };
            });
        },
        search: function() {
            this.setDataTableParams();

            if (this.dataTableOptions.page > 1) this.dataTableOptions.page = 1;
            else this.dataTableInstance.loadData();
        },
        reset: function() {
            // 선택된 열 초기화
            this.selectedKey = null;
            this.selectedRows = [];
            // 상세 정보 초기화
            this.knowledgeDetail = {
                ID: null,
                MAIN_CTGR_CD: null,
                MAIN_CTGR_NM: null,
                SUB_CTGR_CD: null,
                SUB_CTGR_NM: null,
                TITLE: null,
                CONTENT: null,
                REG_ID: null,
                REG_NM: null,
                UPD_ID: null,
                UPD_NM: null,
                UPD_DTTM: null
            };
        },
        save: function() {
            if (!this.knowledgeDetail.MAIN_CTGR_CD) {
                this.common_alert("지식정보 대분류를 선택해야 합니다.", "noti");
            }
            else if (!this.knowledgeDetail.TITLE) {
                this.common_alert("지식정보명을 입력해야 합니다.", "noti");
            }
            else if (!this.knowledgeDetail.CONTENT) {
                this.common_alert("지식정보 내용을 입력해야 합니다.", "noti");
            }
            // DB Text Type의 최대 사이즈 65535Byte를 넘지 않도록 제한
            // 캡쳐 이미지를 저장할 경우 이미지를 바이너리로 변환하고 이를 다시 base64 인코딩으로 변환하여 img의 src 값을 넣기 때문에 최대사이즈를 쉽게 넘을 수 있음
            else if (this.contentLen > 65535) {
                this.common_alert(`내용은 65,535Byte를 초과할 수 없습니다.\n현재 ${this.contentLen}Byte 입력하셨습니다.`, "noti");
            }
            else {
                this.common_confirm('저장하시겠습니까?', this.saveKnowledge, null, null, null, 'chk');
            }
        },
        saveKnowledge: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "save",
                    SERVICE: "knowledge",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/knowledge/save"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData = Object.assign({}, this.knowledgeDetail, { CONTENT: this.restoreXSS_CKeditorDec(this.knowledgeDetail.CONTENT) });
            requestData.sendData.USER_ID = this.$store.getters["userStore/GE_USER_ROLE"].userId;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                let data = res.DATA[0];
                data.TITLE = this.restoreXSS(data.TITLE);
                data.CONTENT = this.restoreXSS_CKeditor(data.CONTENT);
                data.UPD_DTTM = data.UPD_DTTM.substr(0, 19);
                data.REG_DTTM = data.REG_DTTM.substr(0, 19);
                this.selectedRows = res.DATA;
                this.knowledgeDetail = data;
                this.common_alert("정상 처리되었습니다.", "done");
                this.search();
            }).finally(() => {
                this.isLoading = false;
            });
        },
        delete: function() {
            this.common_confirm('삭제하시겠습니까?', this.deleteKnowledge, null, null, null, 'chk');
        },
        deleteKnowledge: function() {
            this.isLoading = true;

            // header 세팅
            let requestData = {
                headers: {
                    ASYNC: false,
                    METHOD: "delete",
                    SERVICE: "knowledge",
                    TYPE: "BIZ_SERVICE",
                    URL: "/api/knowledge/delete"
                },
                sendData: {},
            };

            // sendData 세팅
            requestData.sendData.ID = this.knowledgeDetail.ID;

            this.common_postCall(requestData).then(res => {
                if (this.mixin_isEmpty(res) || res.HEADER.ERROR_FLAG) {
                    this.common_alert(`시스템에 오류가 발생하였습니다.`, "error");
                    return false;
                }
                this.reset();
                this.common_alert("정상 처리되었습니다.", "done");
                this.search();
            }).finally(() => {
                this.isLoading = false;
            });
        },

        fromOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.from) && moment(this.from).isValid()) {
                // 종료일자보다 이후를 입력했으면 종료일자로 변경
                if (moment(this.from).isAfter(this.dates[1])) {
                    this.$nextTick(() => {
                        this.from = this.dates[1];
                    });
                }

                this.$nextTick(() => {
                    this.dates[0] = this.from;
                    // datepick 다시 랜더링
                    this.menuStartDate = false;
                    this.menuStartDate = true;
                });
            }
        },
        startDate(e) {
            this.from = e;
        },
        toOnInput: function() {
            // 날짜 포맷과 일치하고 입력일자에 문제가 없을 때만 datepicker에 반영
            if (/^\d{4}-\d{2}-\d{2}$/.test(this.to) && moment(this.to).isValid()) {
                // 시작일자보다 이전을 입력했으면 시작일자로 변경
                if (moment(this.to).isBefore(this.dates[0])) {
                    this.$nextTick(() => {
                        this.to = this.dates[0];
                    });
                }
                
                this.$nextTick(() => {
                    this.dates[1] = this.to;
                    // datepick 다시 랜더링
                    this.menuEndDate = false;
                    this.menuEndDate = true;
                });
            }
        },
        endDate(e) {
            this.to = e;
        },

        onClickRow: function(item, row) { 
            if (this.selectedKey === item.ID) {
                row.select(false);
                this.selectedKey = null;
                this.knowledgeDetail = {
                    ID: null,
                    MAIN_CTGR_CD: null,
                    MAIN_CTGR_NM: null,
                    SUB_CTGR_CD: null,
                    SUB_CTGR_NM: null,
                    TITLE: null,
                    CONTENT: null,
                    REG_ID: null,
                    REG_NM: null,
                    UPD_ID: null,
                    UPD_NM: null,
                    UPD_DTTM: null
                };
            }
            else {
                row.select(true);
                this.selectedKey = item.ID;
                this.knowledgeDetail = Object.assign(this.knowledgeDetail, item);
            }
        },
        
        filteredMainCategoryList: function() {
            return _.filter(this.mainCategoryList, (item) => {
                return item.value;
            });
        },
        changeMainCategoryCode: function() {
            this.knowledgeDetail.SUB_CTGR_CD = null;
        },

        showDialog(type) {
            //팝업 파라미터 설정
            this.popupParams;
            this[`dialog${type}`] = true;
        },
        hideDialog(type) {
            this[`dialog${type}`] = false;
        }
    },
    created() {},
    mounted() {
        this.init();
    },
    watch: {
        "searchValue.MAIN_CTGR_CD": async function (newValue) {
            this.searchValue.SUB_CTGR_CD = null;
            if (newValue) {
                const resCategoryList = await this.getCategoryList("SUB", newValue);

                if (
                !this.mixin_isEmpty(resCategoryList) &&
                !resCategoryList.HEADER.ERROR_FLAG
                ) {
                    this.subCategoryList = [
                        { text: "전체", value: null },
                        ...resCategoryList.DATA
                    ];
                }
            } else {
                this.subCategoryList = [
                    { text: "전체", value: null }
                ];
            }
        },
        "knowledgeDetail.MAIN_CTGR_CD": async function (newValue) {
            if (newValue) {
                const resCategoryList = await this.getCategoryList("SUB", newValue);

                if (
                !this.mixin_isEmpty(resCategoryList) &&
                !resCategoryList.HEADER.ERROR_FLAG
                ) {
                    this.filteredSubCategoryList = resCategoryList.DATA;
                }
            } else {
                this.filteredSubCategoryList = [];
            }
        }
    }
};
</script>

<style></style>  